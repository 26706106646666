import "./pull-quote.scss";

import cn from "clsx";

import { ReactNode } from "react";
import { Alignment, EditAttributes } from "design-system/types/types";
import { MediaAsset } from "design-system/components/primitives/media-asset/media-asset";
import { PersonTeaseProps } from "design-system/components/primitives/person-tease/person-tease";
import { Link } from "design-system/components/primitives/link/link";

export interface PullQuoteProps {
  align?: Alignment;
  quote?: ReactNode;
  attribution?: PersonTeaseProps;
  hideQuoteMarks?: boolean;
  editAttributes?: {
    quote?: EditAttributes;
    attribution?: EditAttributes;
  };
}

export function PullQuote({
  align,
  quote,
  attribution,
  hideQuoteMarks,
  editAttributes,
}: PullQuoteProps) {
  const wrapperClass = cn(
    align && `hbs-global-align-${align}`,
    "hbs-component--pull-quote",
  );
  return (
    <div className={wrapperClass}>
      <blockquote className="hbs-pullquote">
        <div
          className={cn(`hbs-pullquote__text`, hideQuoteMarks && `hidequote`)}
          {...editAttributes?.quote}
        >
          {quote}
        </div>

        {attribution && (
          <div
            className="hbs-pull-quote__attribution"
            {...editAttributes?.attribution}
          >
            <MediaAsset image={attribution.portrait} />
            <span>
              {attribution.link ? (
                <Link href={attribution.link}>{attribution.name}</Link>
              ) : (
                attribution.name
              )}
              {attribution.title && attribution.title.length > 0 && (
                <span>
                  {", "}
                  {attribution.title.map((item, index) => (
                    <span key={index}>
                      {item}
                      {index + 1 !== attribution?.title?.length ? "; " : ""}
                    </span>
                  ))}
                </span>
              )}
            </span>
          </div>
        )}
      </blockquote>
    </div>
  );
}
