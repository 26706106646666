import { CtaLinkProps } from "design-system/components/primitives/cta-link/cta-link";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import {
  LinkEntry,
  SocialMediaLinkEntry,
} from "frontend/contentful/schema/primitives";
import { useContentful } from "frontend/hooks/use-contentful";

export const createCtaLinkProps = createContentfulProps<
  LinkEntry | SocialMediaLinkEntry,
  CtaLinkProps
>(({ entry, createEditAttributes }) => {
  const { getEntryUrl } = useContentful();

  let href = undefined;
  let children = undefined;
  if (isContentType<LinkEntry>("link", entry)) {
    href = getEntryUrl(entry);
    children = entry.fields.text;
  } else if (isContentType<SocialMediaLinkEntry>("socialMediaLink", entry)) {
    href = entry.fields.url;
    children = entry.fields.socialMedia;
  }
  return {
    href: href,
    children: children,
    editAttributes: createEditAttributes({ entry, fieldId: "text" }),
  };
});
